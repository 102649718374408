<template>
  <div class="personAdmin">
    <div class="facilityBox"> 
      <!--tab 栏导航-->
       <el-tabs v-model="appId" @tab-click="handleClick">
        <el-tab-pane :label="item.AppName" :name="item.ID" v-for="item in tabsList" :key="item.ID">
        </el-tab-pane>

      </el-tabs>
      <div class="topbtns">
        <el-button type="success" size="medium" @click="addPerson('add')" icon="el-icon-circle-plus-outline">添加</el-button>
        <el-button type="danger" size="medium" @click="deleteUser" icon="el-icon-remove-outline">删除</el-button>
      </div>
      <el-form
        class="searchForm" 
        :model="searchForm"
        ref="searchForm">
        <el-form-item label="姓名" prop="EnterpriseFullName" label-width="50px">
          <el-input
            v-model="searchForm.name"
            placeholder="请输入姓名"
            @keyup.enter.native="search('searchForm')"
          ></el-input>
        </el-form-item>

        <el-form-item label="日期查询" prop="field3" label-width="90px">
          <el-date-picker
            v-model="searchForm.dateTime"
            type="daterange"
             @change="search('searchForm')"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button type="primary" size="medium" @click="search('searchForm')" icon="el-icon-search"
            >搜索</el-button
          >
          <el-button type="primary" size="medium" @click="resetForm" icon="el-icon-delete"
            >清空</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        row-key="ID"
        ref="table"
        @selection-change="tableSelectionChange"
        :header-cell-style="{color: '#666', background: '#f0f0f0'}"
        v-loading="loading"
      >
        <el-table-column
          type="selection"
          :selectable="selectable"
          :reserve-selection="true"
          align="center"
          width="50"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="sindex"
          label="序号"
          width="50"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column align="center" width="120" prop="ChineseName" label="员工姓名" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.ChineseName }}
          </template>
        </el-table-column>
        <el-table-column align="center" width="120" prop="Name" label="员工手机号" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.Name }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="IdentityCard"
          label="员工身份证号"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.IdentityCard }}
          </template>
        </el-table-column>
        <!--   <el-table-column align="center" prop="company" label="所属公司">
      </el-table-column>
      <el-table-column align="center" prop="department" label="所属部门">
      </el-table-column>-->
        <el-table-column align="center" width="120" prop="Title" label="职务" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.Title }}
          </template>
        </el-table-column>
        <el-table-column align="center" width="260" prop="RoleName" label="获得角色" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.RoleName }}
          </template>
        </el-table-column>
        <el-table-column align="center" width="180" prop="RoleStatus" label="角色使用状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <div>{{ scope.row.RoleStatus }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" width="180"  label="人员使用状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              <el-tag :type="scope.row.Enabled ? 'success': 'danger'">{{ scope.row.Enabled ? '启用' : '停用' }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" width="220" prop="CreateTime" label="创建时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.CreateTime }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Remark" label="备注" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.Remark }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right"  width="300">
          <template slot-scope="scope" v-if="scope.row.RoleName != '管理员'">
            <el-button type="primary" size="small" @click="giveRole('add', scope.row)" icon="el-icon-user-solid">赋予角色</el-button>
            <el-button type="primary" size="small" @click="addPerson('edit', scope.row)" icon="el-icon-edit">编辑</el-button>
            <!--      <el-button
            type="danger"
            size="medium"
            @click="giveRole('delete', scope.row)"
            >删除角色</el-button
          > -->
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div style="text-align: left; margin-top: 10px">
        <el-pagination background
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.page"
          :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </div>
    <!--添加编辑人员-->
    <el-dialog class="deep_dialog"
      :visible.sync="showPersonInfo"
      width="540px"
    >
      <span slot="title">{{type == 'add' ? '添加人员' : '编辑人员'}}</span>
      <el-form
        :model="personInfo"
        label-width="116px"
        ref="personInfo"
        :rules="addrules"
      >
        <el-form-item label="员工姓名" prop="name">
          <el-input
            style="width: 314px"
            v-model="personInfo.name"
            maxlength="15"
            auto-complete="off"
            clearable
            placeholder="请输入姓名"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input
            style="width: 314px"
            v-model="personInfo.phone"
            auto-complete="off"
            clearable
            placeholder="请输入号码"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="password" v-show="type == 'edit'">
          <el-input
            style="width: 314px"
            v-model="personInfo.password"
            auto-complete="off"
            clearable
            type="password"
            placeholder="请输入登录密码"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="num">
          <el-input
            style="width: 314px"
            v-model="personInfo.num"
            auto-complete="off"
            maxlength="18"
            clearable
            placeholder="请输入号码"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="员工职务" prop="job">
          <el-input
            style="width: 314px"
            v-model="personInfo.job"
            maxlength="20"
            auto-complete="off"
            clearable
            placeholder="请输入职位"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="使用状态" prop="state">
          <el-select
            style="width: 314px"
            v-model="personInfo.state"
            placeholder="请选择状态"
          >
            <el-option
              v-for="item in stateOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            style="width: 314px"
            v-model="personInfo.remark"
            auto-complete="off"
            maxlength="200"
            clearable
            placeholder="请输入备注"
            type="textarea"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          v-show="type == 'add'"
          @click="addTable('personInfo')"
          >添加</el-button
        >
        <el-button
          type="primary"
          v-show="type == 'edit'"
          @click="onSumbitEdit('personInfo')"
          >确定修改</el-button
        >
        <el-button @click="showPersonInfo = false">取 消</el-button>
      </div>
    </el-dialog>
    <!--赋予角色弹框-->
    <el-dialog class="deep_dialog"
      width="800px"
      :visible.sync="giveRoleShow"
    >
      <span slot="title">{{roleType == 'add' ? '赋予角色' : '删除角色'}}</span>
      <el-table
        :data="roleData"
        :header-cell-style="{color: '#666', background: '#f0f0f0'}"
        @selection-change="selectRole"
        ref="myTable"
        v-loading="loading"
        max-height="570"
      >
        <el-table-column type="selection"  align="center" width="50">
        </el-table-column>
        <el-table-column
          align="center"
          type="index"
          prop="UserID"
          label="序号"
          width="50"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column align="center"  prop="Name" label="角色名称" show-overflow-tooltip>
        </el-table-column>
        <!-- <el-table-column align="center" prop="company" label="所属公司">
       
      </el-table-column> -->
        <!-- <el-table-column align="center" prop="department" label="所属部门"  width="200">
         
      </el-table-column> -->
        <el-table-column align="center" prop="Remark" label="角色描述" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PeopleNums" label="赋予人数" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Status" label="使用状态" show-overflow-tooltip>
           <template slot-scope="scope">
             <el-tag v-if="scope.row.Enabled !== 0" type="success">{{ scope.row.Status }}</el-tag>
             <el-tag v-else type="danger">{{ scope.row.Status }}</el-tag>
            <!-- <div :style="scope.row.Enabled === 0 ? 'color: #FF2323' : 'color: #13D298'">{{ scope.row.Status }}</div> -->
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="CreateDatetime"
          label="创建时间"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
      <!--    <el-pagination background
        class="pagination"
        @current-change="handleCurrentChangeRole"
        :current-page.sync="paginationRole.page"
        :page-size="paginationRole.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="paginationRole.total"
      >
      </el-pagination> -->
      <el-alert
        type="warning" style="margin-top:10px"
        :closable="false">
        <span slot="title">
          <span>温馨提示：</span><br/>
          <span>1.勾选一个或多个角色，点击"确认"按钮，即可赋予角色</span><br/>
          <span>2.如需要减少角色，只需将序号前的√取消</span><br/>
        </span>
      </el-alert>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSureRole">{{
          roleType == "add" ? "确认" : "确认"
        }}</el-button>
        <el-button @click="giveRoleShow = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getUserList,
  addUser,
  editUser,
  DeleteUser,
  giveUserRole,
  getAllList,
  getMpList
} from "@/api/power/person";
import { getRoleList } from "@/api/power/role";
import md5 from "js-md5";
import basicMixin from "@/mixins/basic";
export default {
  mixins: [basicMixin],
  data() {
    return {
      loading: false,
      activeName: 'first', // tabs 栏
      pagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 获取角色列表分页
      paginationRole: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      stateOption: [
        {
          value: true,
          label: "启用",
        },
        {
          value: false,
          label: "停用",
        },
      ],
      personInfo: {
        name: "",
        phone: "",
        num: "",
        job: "",
        state: "",
        remark: "",
        password: "",
      }, // 人员信息
      // 添加表单验证
      addrules: {
        name: [
          {
            required: true,
            message: "请输入员工姓名",
            max: 50,
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入员工手机号", trigger: "change" },
          {
            required: true,
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        password: [
          {
            pattern:
              /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9])(.{6,})|(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9])(?=.*[`~@!#$%^&*()_\-+=<>?:\"{}|,.\/;'\\[\]])(.{6,})$/,
            message: "请填写必须包含字母,数字,长度不能少于6位的密码",
            max: 16,
            trigger: "blur",
          }

        ],
        num: [
          {
            required: true,
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: "请正确的身份证号码",
            trigger: "blur",
          },
        ],

        job: [
          { required: true, message: "请输入职务", max: 50, trigger: "change" },
        ],
        state: [
          { required: true, message: "请选择使用状态", trigger: "change" },
        ],
        remark: [{ message: "请填写备注信息", max: 500, trigger: "blur" }],
      },
      showPersonInfo: false, // 显示添加编辑人员弹窗
      giveRoleShow: false, // 赋予角色弹框
      selectedTableItemList: [],
      personId: 0,
      type: "add", // add 为添加人员，edit为编辑
      editInfo: {
        ID: "",
      }, // 编辑的信息
      searchForm: {
        EnterpriseFullName: "", //企业名称
        dateTime: ["", ""], //时间范围
        name: "", //姓名
      },
      roleType: "", // 是赋予角色还是删除角色
      roleSelectionList: [], // 选择了的角色列表
      tableData: [], //表格数据
      roleData: [],
      tabsList: [], // tab 导航列表
      appId: '', // 选中tab栏的id
    };
  },
  methods: {
     // 让管理员账号不可删除
    selectable(row,index){
      if(row.RoleName == '管理员') return false
      return true

    },
    // tabs 切换事件
     handleClick(tab){
       this.pagination.pageIndex = 1
       this.$refs.table.clearSelection();
       this.getUserLists()

   },
    // 添加人员
    addTable(personInfo) {
      this.$refs[personInfo].validate((valid) => {
        if (valid) {
          let params = {
            ChineseName: this.personInfo.name,
            Name: this.personInfo.phone,
            Enabled: this.personInfo.state,
            IdentityCard: this.personInfo.num.toUpperCase(),
            Remark: this.personInfo.remark,
            title: this.personInfo.job,
            appId: this.appId
          };
          addUser(params).then((res) => {
            this.showPersonInfo = false;
            this.$refs[personInfo].resetFields();
            this.pagination.page = 1;
            this.$message({
              message: "添加成功",
              type: "success",
            });
            let params = {
              name: this.searchForm.name,
              DateStart: this.searchForm.dateTime[0],
              DateEnd: this.searchForm.dateTime[1],
              pageSize: this.pagination.pagesize,
              pageIndex: this.pagination.page,
              appId: this.appId
            };
            this.loading = true
            getUserList(JSON.stringify(params)).then((res) => {
              this.tableData = res.data.DataList;
              let i = 1;
              this.tableData.forEach((element) => {
                element.sindex =
                  (this.pagination.page - 1) * this.pagination.pagesize + i++;
              });
              this.pagination.total = Number(res.data.TotalCount);
              this.loading = false
            }).finally(() => {
 
            this.loading = false
      });
          });
        } else {
          this.$message.error("请填写正确的信息");
          return false;
        }
      });
    },

    // 修改人员
    onSumbitEdit(personInfo) {
      this.$refs[personInfo].validate((valid) => {
        if (valid) {
          // 如果有输入了密码则加密，没有输入则为空
          let pwd = this.personInfo.password ? md5(String(this.personInfo.password)) : ''
          let params = {
            ID: this.editInfo.ID,
            ChineseName: this.personInfo.name,
            Name: this.personInfo.phone,
            Enabled: this.personInfo.state,
            password: pwd,
            IdentityCard: this.personInfo.num.toUpperCase(),
            Remark: this.personInfo.remark,
            title: this.personInfo.job,
            appId: this.appId
          };
          editUser(params).then((res) => {
            this.showPersonInfo = false;
            this.$refs[personInfo].resetFields();
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.getUserLists();
          });
        } else {
          this.$message.error("请填写正确的信息");
          return false;
        }
      });
    },
    // 赋予角色
    onSureRole() {
      let arr = [];
      this.roleSelectionList.forEach((item) => {
        arr.push(item.ID);
      });
      let param = arr.join(",");
      if (this.roleType == "add") {
        let params = {
          userId: this.personId,
          roleIds: param,
          appId: this.appId
        };
        giveUserRole(params).then((res) => {
          this.paginationRole.page = 1;
          this.pagination.page = 1;
          this.$message({
            message: "赋予角色成功",
            type: "success",
          });
          this.giveRoleShow = false;
          this.getUserLists();
        });
      } else if (this.roleType == "delete") {
        let params = {
          userId: this.personId,
          roleIds: param,
          status: 0,
          appId: this.appId
        };
        giveUserRole(params).then((res) => {
          this.paginationRole.page = 1;
          this.pagination.page = 1;
          this.$message({
            message: "删除角色成功",
            type: "success",
          });
          this.giveRoleShow = false;
          this.getUserLists();
        });
      }
    },
    //tbale选中改变
    tableSelectionChange(e) {
      this.selectedTableItemList = e;
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      let params = {
        UserName: this.searchForm.name,
        DateStart: this.searchForm.dateTime[0],
        DateEnd: this.searchForm.dateTime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: e,
         appId: this.appId
      };
      this.loading = true
      getUserList(JSON.stringify(params)).then((res) => {
        this.tableData = res.data.DataList;
        let i = 1;
        this.tableData.forEach((element) => {
          element.sindex =
            (this.pagination.page - 1) * this.pagination.pagesize + i++;
        });
        this.pagination.total = Number(res.data.TotalCount);
        this.loading = false
      }).finally(() => {
 
            this.loading = false
      });;
    },
    //搜索
    search(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //搜索前初始化page
          let params = {
            UserName: this.searchForm.name,
            DateStart: this.searchForm.dateTime[0],
            DateEnd: this.searchForm.dateTime[1],
            pageSize: this.pagination.pagesize,
            pageIndex: 1,
             appId: this.appId
          };
          this.pagination.page = 1;
          this.loading = true
          getUserList(JSON.stringify(params)).then((res) => {
            this.tableData = res.data.DataList;
            let i = 1;
            this.tableData.forEach((element) => {
              element.sindex =
                (this.pagination.page - 1) * this.pagination.pagesize + i++;
            });
            this.pagination.total = Number(res.data.TotalCount);
            this.loading = false
          }).finally(() => {
 
            this.loading = false
      });;
        }
      });
    },
    //清空
    resetForm() {
      this.searchForm.EnterpriseFullName = "";
      this.searchForm.name = "";
      this.searchForm.dateTime = [];
      this.pagination.page = 1;
      let params = {
        UserName: this.searchForm.name,
        DateStart: this.searchForm.dateTime[0],
        DateEnd: this.searchForm.dateTime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: 1,
         appId: this.appId
      };
      this.loading = true
      getUserList(JSON.stringify(params)).then((res) => {
        this.tableData = res.data.DataList;
        let i = 1;
        this.tableData.forEach((element) => {
          element.sindex =
            (this.pagination.page - 1) * this.pagination.pagesize + i++;
        });
        this.pagination.total = Number(res.data.TotalCount);
        this.loading = false
      }).finally(() => {
 
            this.loading = false
      });;
    },
    // 如果是工运宝 只能赋予一个角色
    selectOneRole(selection, row) {
      if(this.appId == 'P00002') {
        console.log(selection, row)
        this.$refs.myTable.clearSelection();
         if (selection.length === 0) { // 判断selection是否有值存在
        return;
      };
       this.$refs.myTable.toggleRowSelection(row, true);
      }
    },
    // 选择人员角色
    selectRole(val) {
      // 如果是工运宝 只支持赋予一种角色
      if(this.appId == 'P00002') {
        if (val.length > 1) {
          this.$refs.myTable.clearSelection();
          this.$refs.myTable.toggleRowSelection(val.pop());
        } else {
          this.roleSelectionList = val;
        }
        // this.multipleSelectionUpdate = val[0];// 这种赋值同样不会实时更新
        this.$set(this.roleSelectionList, val[0]);
        console.log(this.multipleSelectionUpdate, this.multipleSelection); // 此时要回显到页面的值
      } else {
        this.roleSelectionList = val;
      }
    },
    //新增
    addPerson(scope, row) {
      this.showPersonInfo = true;
      if (scope == "add") {
        this.title = "add";
        this.type = "add";
        this.$refs["personInfo"].resetFields();
        this.personInfo.name = "";
        this.personInfo.phone = "";
        this.personInfo.num = "";
        this.personInfo.job = "";
        this.personInfo.state = "";
        this.personInfo.remark = "";
      } else if (scope == "edit") {
        this.type = "edit";
        this.editInfo = row;
        this.personInfo.name = row.ChineseName;
        this.personInfo.phone = row.Name;
        this.personInfo.num = row.IdentityCard;
        this.personInfo.job = row.Title;
        this.personInfo.state = row.Enabled;
        this.personInfo.remark = row.Remark;
      }
    },
    // 赋予角色弹框
    giveRole(type, row) {
      this.roleType = type;
      this.personId = row.ID;
      this.giveRoleShow = true;
      if (type == "add") {
        this.title = "add";
      } else if (type == "delete") {
        this.roleType = "delete";
      }
      let params = {
        userId: this.personId,
        appId: this.appId
      };
      getAllList(params).then((res) => {
        this.roleSelectionList = [];
        this.roleData = res.data;
        this.$nextTick(() => {
          res.data.forEach((row) => {
            if (row.Own) {
              this.roleSelectionList.push(row);
              this.$refs.myTable.toggleRowSelection(row, true);
            }
          });
        });
      });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.myTable.toggleRowSelection(row, true);
        });
      } else {
        this.$refs.myTable.clearSelection();
      }
    },
    //批量删除
    deleteUser() {
      if (this.selectedTableItemList.length === 0) {
        this.$message.error("请在表格中勾选要删除的数据");
        return;
      }
      let arr = [];
      this.selectedTableItemList.forEach((item) => {
        arr.push(item.ID);
      });
      
      let params = {
        appid: this.appId,
        userIds: arr
      }
      this.$confirm(
        "您将删除此员工,关于此员工的所有数据将清空，是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          DeleteUser(params).then((res) => {
            this.pagination.page = 1;
            this.searchForm.EnterpriseFullName = "";
            this.searchForm.name = "";
            this.searchForm.dateTime = [];
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getUserLists();
          });
        })
        .catch(() => {});
    },
    // 获取人员列表
    getUserLists() {
      let params = {
        UserName: this.searchForm.name,
        DateStart: this.searchForm.dateTime[0],
        DateEnd: this.searchForm.dateTime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        appId: this.appId
      };
      this.loading = true
      getUserList(JSON.stringify(params)).then((res) => {
        this.tableData = res.data.DataList;
        let i = 1;
        this.tableData.forEach((element) => {
          element.sindex =
            (this.pagination.page - 1) * this.pagination.pagesize + i++;
        });
        this.pagination.total = Number(res.data.TotalCount);
        this.loading = false
      }).finally(() => {
 
            this.loading = false
      });;
    },
    // 获取tabs栏列表
    getMpList() {
       getMpList().then((res) => {
            this.tabsList = res.data
            this.appId = res.data[0].ID
            this.getUserLists();
          });
    },
    // 获取角色列表
    getRoleList() {
      let params = {
        RoleName: "",
        DateStart: "",
        DateEnd: "",
        pageSize: this.paginationRole.pagesize,
        pageIndex: this.paginationRole.page,
        userId: 0,
        appId: this.appId
      };
      this.loading = true
      getRoleList(JSON.stringify(params)).then((res) => {
        this.roleData = res.data.DataList;
        let i = 1;
        this.tableData.forEach((element) => {
          element.sindex =
            (this.pagination.page - 1) * this.pagination.pagesize + i++;
        });
        this.loading = false
        this.paginationRole.total = Number(res.data.TotalCount);
      }).finally(() => {
 
            this.loading = false
      });;
    },
    // 角色列表分页
    /*  handleCurrentChangeRole(e) {
      let params = {
        UserName: "",
        DateStart: "",
        DateEnd: "",
        pageSize: this.paginationRole.pagesize,
        pageIndex: e,
      };
      getRoleList(JSON.stringify(params)).then((res) => {
        this.roleData = res.data.DataList;
        this.paginationRole.total = Number(res.data.TotalCount);
      });
    },*/
  },

  created() {
    this.getMpList()
  },
};
</script>
<style lang="scss">
.personAdmin {
  .addPerson {
  .el-dialog__body {
    display: flex;
    justify-content: center;
  
  }
  }
.infos {
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 16px;
  width: 320px;
  display: flex;
  justify-content: center;
  .el-form-item {
    margin-bottom: 0;
  }
  .el-form-item__label {
    text-align: left;
    color: #333333;
  }
  .el-form-item__error {
    left: 14px !important;
  }
  .el-input__inner {
    border: none;
  }
}
.info {
  margin-bottom: 16px;
  width: 320px;
  font-size: 14px;
  padding-left: 6px;
  .el-form-item {
    margin-bottom: 0;
  }
  .el-textarea__inner {
    height: 80px;
  }
  .name {
    margin-bottom: 8px;
    font-size: 14px;
    color: #333333;
  }
  .el-input__inner {
    border: none;
  }
}
}
</style>
<style lang="scss" scoped>
@import "../../../../assets/style/variable.scss";
.personAdmin {
  .topbtns{
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }
  .roleBtn {
    background: $primary;
    display: flex;
    align-items: center;
    display: inline-block;
    color: #fff;
    padding: 4px 8px;
    border-radius: 6px;
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0px 3px 6px rgba(32, 129, 255, 0.3);
    img {
      height: 22px;
      width: 22px;
      vertical-align: middle;
      margin-right: 4px;
    }
  }
  .btns {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }

  .searchForm {
    display: flex;
    flex-wrap: wrap;
  }
  .addForm {
    display: flex;
    flex-wrap: wrap;
    .el-item {
      width: 50%;
    }
    .el-date-editor {
      width: 100% !important;
    }
  }
  .pagination {
    margin-top: 10px;
  }
  .addDialog {
    .body {
      .el-autocomplete {
        width: 100%;
      }
      .choose {
        margin-top: 50px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
.deep_dialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }
  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }
  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}
</style>